<template>
  <div
    :id="popupId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Export Tasks In Excel Sheet</h2>
          <button
            ref="popupCloseButton"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
            <div class="row" style="border:1px solid #12db6d; padding: 5%;" v-if="step == 1">
              <div class="col-sm-4">

              </div>
              <div class="col-sm-4">
                <h3>Select Date Range</h3>
                 <date-range-picker
                    v-model="dateRange"
                    :opens="'center'"
                    :date-format="dateFormat"
                    :locale-data="{format: 'dd-mm-yyyy' }"
                    :autoApply="true"
                    style="width:100%;margin:auto;"
                  >
                  <template v-slot:input="picker" style="min-width: 350px;">
                      {{ picker.startDate.toDateString() }} - {{ picker.endDate.toDateString() }}
                  </template>
                  </date-range-picker>
              </div>
            </div>
            <div class="row">
                <br/>
                <button class="pull-right  btn-positive" :disabled="submittingForm" @click="exportSelectedPickls" v-html="popButtonLabel"></button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PicklItem from '@/components/common/PicklItem.vue'
import DateRangePicker from 'vue2-daterange-picker'

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  props: ['popupId', 'picklIds', 'brandId', 'campaignId'],
  components: {
    PicklItem,
    DateRangePicker
  },
  data: function () {
    return {
      dateRange: {
        endDate: new Date(),
        startDate: new Date()
      },
      step: 1,
      selectedPicklsList: [],
      hoverRating: 0,
      rating: 0,
      adminNote: '',
      needReview: '',
      ShowNotesToBrand: 1,
      selectedPopOption: 'same-state-same-retailer',
      popButtonLabel: 'EXPORT',
      submittingForm: false
    }
  },
  methods: {
    dateFormat (classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() > new Date()
      }
      return classes
    },
    isSelectedPickl (picklId) {
      if (this.selectedPicklsList.includes(picklId)) {
        return true
      } else {
        return false
      }
    },
    toggleSelectedPicklList (picklId) {
      if (this.selectedPicklsList.includes(picklId)) {
        this.selectedPicklsList.splice(this.selectedPicklsList.indexOf(picklId), 1)
      } else {
        this.selectedPicklsList.push(picklId)
      }
    },
    nextStep () {
      this.step = this.step + 1
    },
    backStep () {
      this.step = this.step - 1
    },
    selectPopType (option) {
      this.selectedPopOption = option
    },
    exportSelectedPickls () {
      console.log(this.dateRange)
      this.popButtonLabel = 'EXPORTING Now, Please wait...'
      this.submittingForm = true
      let payload = this.dateRange
      payload.brandId = this.brandId
      payload.campaignId = this.campaignId
      this.$store.dispatch('exportPickls', payload).then((response) => {
        console.log(response.body)
        this.popButtonLabel = 'EXPORT'
        this.submittingForm = false
        this.$emit('submitted')
        this.$notify({ type: 'success', text: response.body.message, duration: 5000 })

        this.$refs.popupCloseButton.click()
      }).finally((error) => {
        this.popButtonLabel = 'EXPORT'
        this.submittingForm = false
        console.log(error)
      })
    }
  }
}
</script>
<style scoped>
p {
  color: #232323;
  font-size: 24px;
  text-align: center;
}
.rate-picklr {
  cursor: pointer;
  margin-bottom: 45px;
}

.rate-picklr span {
  display: inline-block;
}

.rate-picklr img {
  width: 33px;
  height: 31px;
  margin: 0 8px;
}
table.user-pickl-table>tr {
  border: solid 1px gainsboro;
}
table.user-pickl-table>tr>td {
  padding: 5px 5px;
}

.bottom-margin {
    margin-bottom: 10px;
}
.pickl-selected {
  box-shadow: 0px 0px 10px 2px #12db6d;
}
.pickl {
    cursor: pointer;
}
</style>
